




























































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import VuePaycard from 'vue-paycard';
import { plainToClass } from 'class-transformer';
import _ from 'lodash';
import OrderSummary from '../components/OrderSummary.vue';
import { CheckoutForm } from '@/models/Data/Checkout';
import PaymentMethods from '@/models/enums/PaymentMethods';
import BrasilAPIService from '@/services/third-party/brasil-api/BrasilAPIService';
import LeadService from '@/services/LeadService';
import CommonValidators from '@/validators/CommonValidators';
import CpfValidator from '@/validators/CpfValidator';
import CEPInfo from '@/services/third-party/brasil-api/models/CEPInfo';
import PlatformSelect from '@/components/base-components/PlatformSelect.vue';
import Transaction from '@/models/Transaction';
import TransactionAddress from '@/models/TransactionAddress';
import TransactionStudent from '@/models/TransactionStudent';
import TransactionCreditCard from '@/models/TransactionCreditCard';
import PaymentMethodSelector from '@/components/payment-method-selector/PaymentMethodSelector.vue';
import Student from '@/models/Student';
import PlanService from '@/services/PlanService';
import Plan from '@/models/Plan';
import PlatformButton from '@/components/base-components/PlatformButton.vue';
import ErrorResponse from '@/models/ErrorResponse';

export default Vue.extend({
  name: 'Checkout',
  computed: {
    ...mapGetters(['tenantConfig']),
    ...mapGetters(['checkoutPlan']),
    ...mapGetters(['checkoutCoupon']),
    ...mapGetters(['currentStudent']),
    ...mapGetters(['tenantUrls']),
    ...mapGetters(['appError']),
    nextButtonLabel() {
      switch (this.paymentMethod) {
        case PaymentMethods.BOLETO:
          return this.$t('checkout.next_boleto_payment');
        case PaymentMethods.PIX:
          return this.$t('checkout.next_pix_paymeny');
        default:
          return this.$t('checkout.finish');
      }
    },
    discountCurrentPaymentMethod() {
      return this.discountPaymentMethodAmount(this.paymentMethod, this.creditCard.installments);
    },
    totalAmount() {
      const { installments } = this.creditCard;
      return this.fullAmount - this.discountPaymentMethodAmount(this.paymentMethod, installments);
    },
    fullAmount() {
      if (!this.checkoutPlan) return 0;
      const fullPrice = this.checkoutCoupon?.data
        ? this.checkoutCoupon.data.fee : this.checkoutPlan.fee;
      return fullPrice;
    },
    maxInstallments() {
      return this.checkoutPlan ? this.checkoutPlan.installments : 12;
    },
    installments() {
      return [...Array(this.maxInstallments)].map((value, index) => (
        {
          value: index + 1,
          label: `${index + 1}x de R$ ${(this.creditCardAmountPerInstallment(index + 1) / (index + 1)).toFixed(2).toString().replaceAll('.', ',')}`,
        }));
    },
  },
  data(): CheckoutForm {
    return {
      student: {
        cpf: '',
        firstName: '',
        lastName: '',
        email: '',
        emailConfirmation: '',
        phone: '',
      },
      paymentMethod: PaymentMethods.CREDIT_CARD,
      address: {
        cep: undefined,
        address: undefined,
        number: undefined,
        complement: undefined,
        city: undefined,
        state: undefined,
      },
      creditCard: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
        installments: 1,
      },
      errors: {
        address: {
          cep: undefined,
        },
      },
      renewablePlans: [],
      form: {
        months: [...Array(12)].map((value, index) => ({ value: String(index + 1).padStart(2, '0'), label: `${index + 1} - ${this.$t(`months.${index + 1}`).toString()}` })),
        years: [...Array(10)].map((value, index) => String(new Date().getFullYear() + index)),
      },
      rules: {
        cpfRules: [
          (v) => CommonValidators.hasValue(v) || this.$t('checkout.errors.mandatory_field').toString(),
          (v) => CpfValidator.isValid(v) || this.$t('checkout.errors.invalid_cpf').toString(),
        ],
        mandatoryFieldRules: [
          (v) => CommonValidators.hasValue(v) || this.$t('checkout.errors.mandatory_field').toString(),
        ],
        emailRules: [
          (v) => CommonValidators.isValidEmail(v) || this.$t('checkout.errors.invalid_email').toString(),
        ],
        confirmationEmailRules: [
          (v) => v === this.$data.student.email || this.$t('checkout.errors.emails_not_match').toString(),
        ],
        creditCardMandatoryField: [
          (v) => ((CommonValidators.isPaymentCreditCard(this.$data.paymentMethod) && !CommonValidators.hasValue(v)) ? this.$t('checkout.errors.mandatory_field').toString() : true),
        ],
        creditCardCardNumberRules: [
          (v) => ((CommonValidators.isPaymentCreditCard(this.$data.paymentMethod) && !CommonValidators.isCreditCardValid(v)) ? this.$t('checkout.errors.invalid_credit_card').toString() : true),
        ],
        creditCardCVVRules: [
          (v) => ((CommonValidators.isPaymentCreditCard(this.$data.paymentMethod) && !CommonValidators.isCVVValid(v)) ? this.$t('checkout.errors.invalid_cvv').toString() : true),
        ],
      },
    };
  },
  methods: {
    getDiscountRate(method: PaymentMethods, installments: number): number {
      if (!this.tenantConfig) return 0;

      switch (method) {
        case PaymentMethods.CREDIT_CARD:
          if (installments === 1) {
            return this.tenantConfig.discountCCOneTime;
          }
          return 0;
        case PaymentMethods.BOLETO:
          return this.tenantConfig.discountBoleto;
        case PaymentMethods.PIX:
          return this.tenantConfig.discountPix;
        default:
          return 0;
      }
    },
    discountPaymentMethodAmount(method: PaymentMethods, installments: number) {
      const discountRate = this.getDiscountRate(method, installments);
      if (discountRate) {
        return Math.round((this.fullAmount * (discountRate / 100.0) * 100)) / 100;
      }
      return 0;
    },
    creditCardAmountPerInstallment(installments: number) {
      return this.fullAmount - this.discountPaymentMethodAmount(
        PaymentMethods.CREDIT_CARD, installments,
      );
    },
    loadCEPInfo() {
      this.$store.dispatch('startLoading');
      BrasilAPIService.getCEPInfo(this.address.cep)
        .then((cepInfo: CEPInfo) => {
          this.address.address = cepInfo.street;
          this.address.city = cepInfo.city;
          this.address.state = cepInfo.state;
          this.errors.address.cep = undefined;
        })
        .catch(() => {
          this.address.address = undefined;
          this.address.city = undefined;
          this.address.state = undefined;
          this.errors.address.cep = undefined;
        })
        .finally(() => this.$store.dispatch('finishLoading'));
    },
    getRenewablePlans() {
      this.$store.dispatch('startLoading');
      PlanService.getRenewable()
        .then((plans: Plan[]) => {
          this.renewablePlans = plans;
        })
        .catch(() => {
          this.renewablePlans = [];
        })
        .finally(() => this.$store.dispatch('finishLoading'));
    },
    goToLandingPage() {
      window.location.href = this.tenantUrls.landingPage as string;
    },
    revalidateForm() {
      this.$refs.form.resetValidation();
    },
    onRenewablePlanSelected(selectedId: string) {
      const selected = (this.renewablePlans as Plan[]).find((plan) => plan.id === selectedId);
      this.$store.commit('setCurrentPlan', selected);
    },
    onBlurStudentIdentificationFields() {
      const fields = [this.student.cpf, this.student.email, this.student.firstName,
        this.student.lastName, this.student.phone];
      const hasRequiredInfo = fields.every((field) => !_.isEmpty(field));

      if (hasRequiredInfo) {
        const studentData = plainToClass(TransactionStudent, this.student);
        LeadService.submitNewOpenCartLead(studentData);
      }
    },
    submit(e: Event) {
      e.preventDefault();
      if (!this.$refs.form.validate()) {
        const error: ErrorResponse = {
          status: 0,
          message: 'ERROR_ON_FORM_FIELD',
          errorCode: 'ERROR_ON_FORM_FIELD',
          params: {},
        };
        this.$store.dispatch('setError', { error });
        return;
      }
      const transaction = plainToClass(Transaction, {
        planId: this.checkoutPlan.id,
        coupon: this.checkoutCoupon?.data?.code,
        paymentMethod: this.paymentMethod,
        student: plainToClass(TransactionStudent, this.student),
        address: this.paymentMethod === PaymentMethods.CREDIT_CARD
          ? plainToClass(TransactionAddress, this.address) : undefined,
        creditCard: this.paymentMethod === PaymentMethods.CREDIT_CARD
          ? plainToClass(TransactionCreditCard, this.creditCard) : undefined,
      } as Transaction);

      this.$store.dispatch('submitTransaction', transaction);
    },
  },

  components: {
    OrderSummary,
    VuePaycard,
    PlatformSelect,
    PaymentMethodSelector,
    PlatformButton,
  },
  watch: {
    currentStudent: {
      async handler(current: Student | undefined) {
        if (current) {
          this.student.cpf = current.cpf ?? '';
          this.student.firstName = current.firstName ?? '';
          this.student.lastName = current.lastName ?? '';
          this.student.email = current.email ?? '';
          this.student.emailConfirmation = current.email ?? '';
          this.student.phone = current.phone ?? '';
          this.address.cep = current.zipCode ?? '';
          this.address.address = current.address ?? '';
          this.address.number = current.number ?? '';
          this.address.complement = current.complement ?? '';
          this.address.city = current.city ?? '';
          this.address.state = current.state ?? '';
          this.revalidateForm();
          this.onBlurStudentIdentificationFields();

          if (current.hasPastPlans) {
            this.getRenewablePlans();
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch('getPlanInfo', {
      planId: this.$route.query.plan,
    });

    if (this.$route.query.coupon) {
      this.$store.dispatch('validateCoupon', {
        planId: this.$route.query.plan,
        coupon: this.$route.query.coupon,
      });
    }
  },
});
