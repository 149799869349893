import { Transform } from 'class-transformer';
import CommonFormatter from '@/formatters/CommonFormatter';

class TransactionCreditCard {
  @Transform(({ value }) => CommonFormatter.removeAnyNonNumber(value))
  cardNumber: string;

  cardName: string;

  cardMonth: string;

  cardYear: string;

  cardCvv: string;

  installments: number;
}

export default TransactionCreditCard;
