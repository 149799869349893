import { Transform } from 'class-transformer';
import CommonFormatter from '@/formatters/CommonFormatter';

class TransactionStudent {
  @Transform(({ value }) => CommonFormatter.removeAnyNonNumber(value))
  cpf: string

  firstName: string;

  lastName: string;

  email: string

  @Transform(({ value }) => CommonFormatter.removeAnyNonNumber(value))
  phone: string;
}

export default TransactionStudent;
