import { plainToClass } from 'class-transformer';
import BaseApiRequestService, { HttpMethods } from '../../api/BaseApiRequestService';
import CEPInfo from './models/CEPInfo';

class BrasilAPIService extends BaseApiRequestService {
  protected readonly serviceURL = 'https://brasilapi.com.br/api';

  public async request(
    method: HttpMethods,
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: Record<string, any> = {},
    headers: { [key: string]: string } = {},
  ) : Promise<Response> {
    return super.request(method, url, body, headers);
  }

  async getCEPInfo(cep: string): Promise<CEPInfo> {
    const url = `/cep/v2/${cep}`;
    const response = await this.request('GET', url);

    if (response.ok) {
      const body = await response.json();
      return Promise.resolve(plainToClass(CEPInfo, body));
    }

    return Promise.reject(response.body);
  }
}

export default new BrasilAPIService();
