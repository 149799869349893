import { Transform } from 'class-transformer';
import CommonFormatter from '@/formatters/CommonFormatter';

class TransactionAddress {
  @Transform(({ value }) => CommonFormatter.removeAnyNonNumber(value))
  cep: string;

  address: string;

  number: string;

  complement?: string;

  city: string;

  state: string;

  country: string;
}

export default TransactionAddress;
