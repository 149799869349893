import { plainToClass } from 'class-transformer';
import ErrorResponse from '@/models/ErrorResponse';
import Plan from '@/models/Plan';
import TransactionStudent from '@/models/TransactionStudent';
import PaymentAPIRequestService from './api/PaymentAPIRequestService';

class LeadService extends PaymentAPIRequestService {
  async submitNewOpenCartLead(studentInfo: TransactionStudent): Promise<Plan> {
    const url = '/leads/';
    const response = await this.request('POST', url, studentInfo);
    const body = await response.json();

    if (response.ok) {
      return Promise.resolve(body);
    }

    return Promise.reject(plainToClass(ErrorResponse, body));
  }
}

export default new LeadService();
