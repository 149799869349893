import PaymentMethods from './enums/PaymentMethods';
import TransactionAddress from './TransactionAddress';
import TransactionCreditCard from './TransactionCreditCard';
import UserMetadata from './UserMetadata';
import TransactionStudent from './TransactionStudent';

class Transaction {
  paymentMethod: PaymentMethods;

  student: TransactionStudent;

  address?: TransactionAddress;

  creditCard?: TransactionCreditCard;

  userMetadata?: UserMetadata;

  planId: string;

  coupon: string;
}

export default Transaction;
