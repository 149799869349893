export default class CpfValidator {
  public static isValid(cpf: string): boolean {
    const value = cpf.replace(/[^0-9]+/g, '');
    if (!value || value.length !== 11) {
      return false;
    }

    const firstVerificationDigit = parseInt(value[9], 10);
    const secondVerificationDigit = parseInt(value[10], 10);
    let firstDigitValidation = 0;
    let secondDigitValidation = 0;

    for (let i = 0; i < 9; i += 1) {
      firstDigitValidation += parseInt(value[i], 10) * (10 - i);
      secondDigitValidation += parseInt(value[i], 10) * (11 - i);
    }
    secondDigitValidation += firstVerificationDigit * 2;

    let modFirst = ((firstDigitValidation * 10) % 11);
    modFirst = modFirst >= 10 ? 0 : modFirst;

    let modSecond = ((secondDigitValidation * 10) % 11);
    modSecond = modSecond >= 10 ? 0 : modSecond;

    const isFirstVerificationValid = modFirst === firstVerificationDigit;
    const isSecondVerificationValid = modSecond === secondVerificationDigit;

    return isFirstVerificationValid && isSecondVerificationValid;
  }
}
