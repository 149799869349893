import PaymentMethods from '@/models/enums/PaymentMethods';

class CommonValidators {
  public static hasValue(value: string): boolean {
    return !!value && value.length > 0;
  }

  public static isValidEmail(value: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(value);
  }

  public static isPaymentCreditCard(paymentMethod: PaymentMethods): boolean {
    return paymentMethod === PaymentMethods.CREDIT_CARD;
  }

  public static isCreditCardValid(ccNumber: string): boolean {
    const ccValue = ccNumber.replaceAll(' ', '');
    return /^[0-9]{16}$/.test(ccValue);
  }

  public static isCVVValid(cvv: string): boolean {
    return /^[0-9]{3}$/.test(cvv);
  }
}

export default CommonValidators;
