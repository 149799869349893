class CEPInfo {
  cep: string;

  state: string;

  city: string;

  neighborhood: string;

  street: string;
}

export default CEPInfo;
