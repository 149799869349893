















































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import PaymentMethods from '@/models/enums/PaymentMethods';
import { PaymentMethodSelectorData } from './types';
import PixIcon from '@/assets/icons/PixIcon.vue';

export default Vue.extend({
  components: { PixIcon },
  name: 'PaymentMethodSelector',
  props: {
    fullAmount: {
      type: Number,
    },
    installmentsCC: {
      type: Number,
    },
    value: {
      type: String,
      required: true,
    },
    maxInstallments: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(['tenantConfig']),
    selected: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
  },
  data(): PaymentMethodSelectorData {
    return {
      paymentMethods: {
        [PaymentMethods.CREDIT_CARD]: {
          name: this.$t(`checkout.payment_method.${PaymentMethods.CREDIT_CARD}`).toString(),
          icon: 'credit-card',
        },
        [PaymentMethods.BOLETO]: {
          name: this.$t(`checkout.payment_method.${PaymentMethods.BOLETO}`).toString(),
          icon: 'barcode',
        },
        [PaymentMethods.PIX]: {
          name: this.$t(`checkout.payment_method.${PaymentMethods.PIX}`).toString(),
          icon: 'pix',
        },
      },
    };
  },
  methods: {
    getDiscount(method: PaymentMethods): number | undefined {
      if (!this.tenantConfig) return undefined;
      let discount;

      switch (method) {
        case PaymentMethods.CREDIT_CARD:
          if (this.installmentsCC === 1) {
            discount = this.tenantConfig.discountCCOneTime;
          } else {
            discount = 0;
          }
          break;
        case PaymentMethods.BOLETO:
          discount = this.tenantConfig.discountBoleto;
          break;
        case PaymentMethods.PIX:
          discount = this.tenantConfig.discountPix;
          break;
        default:
          discount = undefined;
      }
      return discount;
    },
    totalAmount(method: PaymentMethods) {
      const discountRate = this.getDiscount(method);

      if (discountRate) {
        const discount = Math.round((this.fullAmount * (discountRate / 100.0)) * 100.0) / 100.0;
        return this.fullAmount - discount;
      }
      return this.fullAmount;
    },
  },

});
